import '@/registerServiceWorker';
import '@/utils/initDevtools';
import 'buefy/dist/buefy.css';
import 'focus-visible';
import '@stripe/stripe-js';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import '@/style/index.sass';
import { getRouter, getStore, initFirebase } from '@caresend/ui-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBirthdayCake,
  faCalendarDay,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloud,
  faCloudUploadAlt,
  faCog,
  faComment,
  faDesktop,
  faDoorOpen,
  faDraftingCompass,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFilePdf,
  faFlag,
  faGlobeAmericas,
  faHome,
  faLink,
  faLock,
  faMapMarker,
  faMobile,
  faPhoneAlt,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faSignInAlt,
  faTimes,
  faTimesCircle,
  faUser,
  faUserPlus,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Buefy from 'buefy';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Vue from 'vue';
import VueMobileDetection from 'vue-mobile-detection';

import App from '@/App.vue';
import ImagePane from '@/components/layout/image/ImagePane.vue';
import FormHeader from '@/components/layout/text/FormHeader.vue';
import PageHeader from '@/components/layout/text/PageHeader.vue';
import { initSegment, initializeSentry } from '@/functions/tracking';
import { initRouter } from '@/router';
import { initStore } from '@/store';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);

initFirebase();
initSegment();
initializeSentry();
initStore();

// TODO: Remove all global components.
Vue.component('ImagePane', ImagePane);
Vue.component('PageHeader', PageHeader);
Vue.component('FormHeader', FormHeader);

// TODO: Remove when all $isMobile removed
Vue.use(VueMobileDetection);

library.add(
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBirthdayCake,
  faCalendarDay,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloud,
  faCloudUploadAlt,
  faCog,
  faComment,
  faDesktop,
  faDoorOpen,
  faDraftingCompass,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFlag,
  faFilePdf,
  faGlobeAmericas,
  faHome,
  faLink,
  faLock,
  faMapMarker,
  faMobile,
  faPhoneAlt,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faSignInAlt,
  faTimes,
  faTimesCircle,
  faUser,
  faUserPlus,
  faVideo,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.use(Buefy, {
  defaultIconComponent: 'FontAwesomeIcon',
  defaultIconPack: 'fas',
});

Vue.config.productionTip = false;

const initApp = async () => {
  await getStore().dispatch('app/initApp');

  initRouter();

  new Vue({
    name: 'Patient',
    router: getRouter(),
    store: getStore(),
    render: (h) => h(App),
  }).$mount('#app');
};

initApp();
